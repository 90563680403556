import React, { FC, useRef, useState } from 'react'
import debounce from 'debounce'
import RangeSlider from 'components/form/RangeSlider'
import useTranslation from 'translations/hooks/useTranslation'
import Select from 'components/form/Select'

type IProps = {
  defaultValue: string
  defaultCurrencyValue: string
  onUpdate: (value: string, currency: string) => void
}

const UPDATE_DEBOUNCE_DELAY = 1000

const FilterOptionSalary: FC<IProps> = ({
  onUpdate,
  defaultValue,
  defaultCurrencyValue,
}) => {
  const [salaryValue, setSalaryValue] = useState(defaultValue)
  const [currencyValue, setCurrencyValue] = useState(defaultCurrencyValue)
  const { t } = useTranslation()

  const notifyValueChanged = useRef(debounce(onUpdate, UPDATE_DEBOUNCE_DELAY))

  const handleSalaryValueChange = (value: string) => {
    setSalaryValue(value)
    notifyValueChanged?.current(value, currencyValue)
  }

  const handleCurrencyValueChange = (value: string) => {
    setCurrencyValue(value)
    notifyValueChanged?.current(salaryValue, value)
  }

  const getCurrencyMark = () => {
    switch (currencyValue) {
      case 'CZK':
        return 'CZK'
      case 'EUR':
      default:
        return '€'
    }
  }

  return (
    <div className="filter-salary-wrapper">
      <div className="filter-salary-column width-full">
        <div className="fiter__positions-wrapper">
          {t('filter.salaryFilterTitle', {
            salaryValue: `${salaryValue} ${getCurrencyMark()}`,
          })}
        </div>
        <div className="filter-salary-range-wrapper">
          <RangeSlider
            value={salaryValue}
            min={0}
            max={10000}
            step={100}
            onChange={(e) => handleSalaryValueChange(e.target.value)}
          />
        </div>
      </div>
      <div className="filter-salary-currency-select-wrapper filter-salary-column">
        <div className="fiter__positions-wrapper">{t('jobs.currency')}</div>
        <Select
          withoutLabel
          label=""
          name=""
          className="no-margin-top no-padding"
          selectClassName="filter-salary-currency-select"
          value={currencyValue}
          onChange={(e) => handleCurrencyValueChange(e.target.value)}
          options={[
            { value: 'EUR', text: 'EUR' },
            { value: 'CZK', text: 'CZK' },
          ]}
        />
      </div>
    </div>
  )
}

export default FilterOptionSalary
