import React, { FC } from 'react'
import classNames from 'classnames'
import FilterPill from 'components/filter/FIlterPill'
import FilterKeyEmoji from 'components/filter/Filter/FilterKeyEmoji'
import { FilterQueryData, IFilterData } from 'types/filter'
import useTranslation from 'translations/hooks/useTranslation'
import FilterTab from 'components/filter/Filter/FilterTab'

type IProps = {
  activeTab: keyof IFilterData | null
  filterQuery: FilterQueryData
  filterData: IFilterData
  onTabClick: (key: keyof IFilterData) => void
  onResetFilterClick?: (e: React.MouseEvent) => void
  additionalFilter?: {
    new?: {
      count: number
      onClick: () => void
      isActive?: boolean
    }
    salary?: boolean
  }
}

const ActiveCountPill: FC<{ value: number }> = ({ value }) => {
  if (value === 0) return null

  return (
    <span
      className="pill pill--super-small pill--orange absolute"
      style={{ top: -8, right: 8 }}
    >
      {value}
    </span>
  )
}

const FilterTabs: FC<IProps> = ({
  activeTab,
  additionalFilter,
  filterData,
  filterQuery,
  onTabClick,
  onResetFilterClick,
}) => {
  const { t } = useTranslation()

  const renderFilterDataKeyPill = (key: keyof IFilterData) => {
    function getBadgeCount() {
      if (key === 'others') {
        return filterQuery?.pro?.length
      }

      if (filterQuery[key]?.length !== 0) {
        if (key === 'salary') {
          return 1
        }

        return Array.isArray(filterQuery[key])
          ? filterQuery[key]?.length || 0
          : [filterQuery[key]].length
      }

      return 0
    }

    return (
      <FilterTab
        isOpen={activeTab === key}
        isActive={getBadgeCount() !== 0}
        onClick={() => onTabClick(key)}
      >
        <FilterKeyEmoji filterKey={key} className="margin-right-10" />
        {t(`filter.key[${key}]`)}
        <ActiveCountPill value={getBadgeCount()} />
      </FilterTab>
    )
  }

  return (
    <div className="filter__tabs-container filter__tabs-list">
      {additionalFilter?.new && (
        <FilterPill
          isActive={additionalFilter.new.isActive}
          className={classNames('relative pill--no-line-through', {
            'pill--filter-inactive': !additionalFilter.new.isActive,
          })}
          onClick={additionalFilter.new.onClick}
        >
          {t('label.new')}
          <ActiveCountPill value={additionalFilter.new.count} />
        </FilterPill>
      )}
      {filterData.jobCategories && renderFilterDataKeyPill('jobCategories')}
      {filterData.tags && renderFilterDataKeyPill('tags')}
      {filterData.skillLevels && renderFilterDataKeyPill('skillLevels')}
      {additionalFilter?.salary && renderFilterDataKeyPill('salary')}
      {filterData.countries && renderFilterDataKeyPill('countries')}
      {filterData.cities && renderFilterDataKeyPill('cities')}
      {filterData.jobTypes && renderFilterDataKeyPill('jobTypes')}
      {filterData.paymentTypes && renderFilterDataKeyPill('paymentTypes')}
      {filterData.others && renderFilterDataKeyPill('others')}
      {onResetFilterClick && (
        <span
          tabIndex={0}
          className="filter__reset margin-left-auto flex flex-items-center"
          onClick={onResetFilterClick}
        >
          <span className="filter__reset-icon">+</span>
          <a href="#">{t('filter.reset')}</a>
        </span>
      )}
    </div>
  )
}

export default FilterTabs
