import React, { FC, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import FilterPill from 'components/filter/FIlterPill'
import FilterUtils from 'utils/filter'
import FilterOptionSalary from 'components/filter/Filter/FilterOptionSalary'
import useTranslation from 'translations/hooks/useTranslation'
import { OptionBase } from 'components/filter/Filter/FilterOptions'
import { FilterQueryData, IFilterData } from 'types/filter'
import { GrammarCaseKey } from 'translations/types'
import StringUtils from 'utils/string'

type IProps = {
  optionsKey: keyof IFilterData
  options: OptionBase[]
  activeOptions: FilterQueryData
  title: string
  eventHandlers: {
    onSelectPill?: (value: string) => void
    onSalaryChange?: (value: string, currency: string) => void
    onTogglePill?: (key: string, value: string) => void
    onSaveAndCloseClick: () => void
  }
  salaryValue?: string
  currencyValue?: string
}

const WRAPPER_VISIBILITY_BREAKPOINT = 1140

const FilterOptionsMobile: FC<IProps> = ({
  optionsKey,
  options,
  eventHandlers,
  activeOptions,
  title,
  salaryValue = '0',
  currencyValue = 'EUR',
}) => {
  const { onSelectPill, onSalaryChange, onTogglePill, onSaveAndCloseClick } =
    eventHandlers
  const { t } = useTranslation()
  const { width } = useWindowSize()

  useEffect(() => {
    if (width <= WRAPPER_VISIBILITY_BREAKPOINT) {
      return document.body.classList.add('modal-visible')
    }

    if (width > WRAPPER_VISIBILITY_BREAKPOINT) {
      return document.body.classList.remove('modal-visible')
    }
  }, [width])

  useEffect(() => {
    return () => {
      return document.body.classList.remove('modal-visible')
    }
  }, [])

  const renderOptionsContent = () => {
    if (optionsKey === 'salary') {
      return (
        <FilterOptionSalary
          onUpdate={onSalaryChange}
          defaultValue={salaryValue}
          defaultCurrencyValue={currencyValue}
        />
      )
    }

    if (!!options[0].category) {
      return (
        <>
          {FilterUtils.groupCategoryData(options).map((optionGroup) => (
            <div key={optionGroup.category.slug}>
              <p className="paragraph paragraph--small paragraph--bold capitalize margin-bottom-tiny">
                {t(`freelancers.categoryName[${optionGroup.category.slug}]`, {
                  grammarCase: GrammarCaseKey.Other,
                })}
              </p>
              {optionGroup.tags.map((tag) => (
                <FilterPill
                  key={tag.slug}
                  onClick={() => onSelectPill(tag.slug)}
                  isActive={activeOptions[optionsKey]?.includes(tag.slug)}
                  filterName={tag.name}
                  filterShortName={tag.shortName}
                  slug={tag.slug}
                />
              ))}
            </div>
          ))}
        </>
      )
    }

    return (
      <>
        {options.map((option) => (
          <FilterPill
            key={option.slug}
            onClick={
              option.isBoolean
                ? () => onTogglePill(option.slug, 'true')
                : () => onSelectPill(option.slug)
            }
            isActive={activeOptions[optionsKey]?.includes(option.slug)}
          >
            {optionsKey === 'jobCategories'
              ? StringUtils.capitalize(
                  t(`freelancers.categoryName[${option.slug}]`, {
                    grammarCase: GrammarCaseKey.Other,
                  })
                ) || t(`common.companyCategoryName[${option.slug}]`)
              : option.name}
          </FilterPill>
        ))}
      </>
    )
  }

  return (
    <div className="mobile-filter-wrapper">
      <div className="filter-options-modal-header">
        <h1 className="paragraph paragraph--bold paragraph--big no-margin-bottom">
          {title}
        </h1>
        <div onClick={onSaveAndCloseClick} className="modal__header-close">
          <span className="button__in">
            <span className="button__in-icon">+</span>
          </span>
        </div>
      </div>
      <div className="mobile-filter-options-wrapper">{renderOptionsContent()}</div>
      <div className="filter-options-modal-footer">
        <span
          onClick={onSaveAndCloseClick}
          className="button-secondary button-secondary--black button--full"
        >
          {t('common.actions.saveAndClose')}
        </span>
      </div>
    </div>
  )
}

export default FilterOptionsMobile
